.Terminos-table {
    margin-bottom: 20px;
    margin-left: 5rem;
    margin-right: 5rem;
    text-align: left;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
}