.faq-container {
    padding: 40px 100px 0px 100px;
}

.faq-acordion-item-pre-container {
    background: transparent !important;
    border: none !important;
}

.faq-acordion-item-pre-container-header button {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.faq-acordion-item-pre-container-header button::after {
    background-image: none !important;
}

.faq-acordion-item-pre-container-header button span {
    background-color: transparent !important;
    font-family: var(--font);
    /* font-size: 25px; */
    font-weight: 600;
    /* line-height: 30px; */
    /* letter-spacing: 0.002em; */
    /* text-align: left; */
    color: white;
    text-align: center !important;
    width: 100%;
    font-family: Oswald;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.002em;
    text-align: left;

}

.faq-acordion-item-pregunta {
    margin-bottom: 10px;
    border-radius: 10px;
}

.faq-acordion-item-pregunta button {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.faq-acordion-item-descripcion {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.002em;
    text-align: left;
}


/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {
    .faq-container {
        padding: 0px;
    }
}