/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 20px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #757575;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 20px;
  width: 20px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
}


/* General sidebar styles */
.bm-menu {
  background: RGBA(0, 0, 0, 0.8); 
  padding: 1em 1.5em 1em 1em;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(0, 0, 0);
  left: 0;
  width: 99% !important;
  opacity: 0 !important;
}

.bm-burger-bars {
  border-radius: 5px !important;
}

.custom-navbar-mobile {
  height: 78px;
  display: flex;
  background-color: RGBA(0, 0, 0, 0.8);
  opacity: 1;
  /* margin-right: 1%; */
  justify-content: space-around;
  align-items: space;
  position: fixed;
  z-index: 2;
  width: 100%;
  padding-top: 10px;

}

.menu-div {
  margin-top: 100px;
  left: 0;
  height: auto;
}

.navbar-buttonM {
  color: white;
  /*margin: 2%;*/
  font-weight: bold;
  text-align: start !important;
  border-bottom-style: solid;
  border-bottom-width: 0.1px;
  border-bottom-color: white;
  font-family: var(--font);
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.linkCarrito {
  align-self: center;
}

a {
  text-decoration: none !important;
}

.menu-div-disable {
  display: none;
}

.menu-div-enable {
  position: fixed;
  z-index: 3;
  left: 8px;
  top: 5px;
}


.user-initial-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E5CB65;
  text-decoration: none;
}

.user-initial {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.user-name {
  font-weight: bold;
  color: white;
  font-family: var(--font);
}

/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {
  .navbar-button-icon {
    margin: 0;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    right: 1rem;
    position: relative;
  }

  .cart-icon-container {
    position: relative;
    display: inline-block;
  }
  
.cart-items-count {
    position: absolute;
    top: -9px;
    right: -9px;
    background-color: #E5CB65;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: bold;
}

  .navbar-button-icon-person {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
  }

  .center-container {
    flex: 1;
    /* Expande el contenedor intermedio para que ocupe todo el espacio disponible */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente los elementos en el eje x */
    padding-left: 70px;
  }

  .logged-username-container {
    display: flex !important;
    align-items: center;
    gap: 0.5rem;
    margin-top: 5px;
  }

  .navbar-img {
    position: absolute;
  }

  .bm-burger-button {
    display: flex;
  }

  .menu-div {
    position: static;
    color: red;
  }

}