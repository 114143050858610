.hero-section {
    position: relative;
    width: 100%;
    height: 500px;
    height: 500px;
    left: 0px;
    top: 0px;
}

.hero-section::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    /* Altura del fadeout */
    background: linear-gradient(transparent, rgba(0, 0, 0, 1));
}

.background-hero-img {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    left: -4px;
    top: -45px;
}

.gradient-hero-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 32.53%, rgba(0, 0, 0, 0.4) 80.06%, #000000 100%);
}

.container-hero-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    position: absolute;
    width: 821px;
    height: 50px;
    top: 50%;
    left: calc(50% - 821px/2 - 0.5px);

}

.text-container-hero-img {
    /* texto */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 821px;
    height: 50px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    background-position-x: -57px;
}

.text-container-hero-img>h1 {
    width: 821px;
    height: 50px;

    /* Headings/H1 Desktop */
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;

    /* identical to box height, or 111% */
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-container-hero-img>span {
    /* Conoce nuestra variedad de cortes selectos de cerdo */
    /* display: none; */
    width: 85vw;
    height: 25px;

    /* Headings/H4 Desktop */
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;

    /* identical to box height, or 125% */
    text-align: center;
    letter-spacing: 0.001em;
    text-transform: uppercase;
    color: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}




@media only screen and (max-width: 600px) {

    .hero-section {
        height: 61vw;
        width: 100vw;
    }

    .text-container-hero-img {
        width: 100%;
    }

    .text-container-hero-img>span {
        font-family: var(--font);
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.001em;
        text-align: center;
        width: 80vw;

    }

    .container-hero-img {
        width: 100%;
        left: auto;
    }

    .text-container-hero-img>h1 {
        font-family: var(--font);
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.002em;
        text-align: center;
        width: 85vw;
        height: auto;
        margin: 0;
    }

    /* .background-hero-img{
        background-position-x: -57px;
    } */

}