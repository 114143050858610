.form-container {
    gap: 2rem
}

.checkout-body {
    background: var(--white-bg);
    border-radius: 15px;
    padding: 3rem 5rem;
}

.red-font {
    color: var(--red-font);
}

.item-label {
    display: flex;
    gap: 5px;
    font: var(--fontText);
    text-align: left;
    margin: 0;
}

.item-label-whatsapp {
    gap: 5px;
    font: var(--fontText);
    text-align: left;
    margin: 0;
}

.whatsapp-link {
    display: flex;
    text-decoration: none;
    align-items: center;
}

.whatsappImage {
    width: 22px !important;
    height: 22px !important;
    margin-left: 5px;
}

.whatsapp-text {
    font-size: 14px;
    /* Ajusta el tamaño del texto según sea necesario */
    font-weight: bold;
    white-space: nowrap;
    color: #57D163;
}


.item-value {
    font: var(--fontText);
    text-align: left;
    font-weight: 700;
}

.warning-text {
    color: var(--red-font);
    font: var(--fontText);
    text-align: left;
}

.producto-comprado {
    width: 320px;
    height: 150px;
}

.PC-img-cont {
    position: relative;
    height: 100%;
    width: 150px;
    flex: 1;
    overflow: hidden;
}

/* .PC-img {
    height: 100%;
    width: 100%;
    border-radius: 5px
} */

.PC-img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.PC-info-cont {
    /* display: flex; */
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    width: auto;
    padding: 5px;
    height: 100%;
    flex: 1
}

.PC-info-cont>h4 {
    font-family: var(--font);
    text-transform: uppercase;
}

.PC-info-piezas {
    font-family: var(--fontText);
}

.PC-info-precio {
    font-family: var(--fontText);
    font-weight: 700;
    font-size: 1.1rem;
}

.productos-comprados-main {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    padding: '1em'
}

.productos-comprados-cont {
    flex-wrap: wrap;
    gap: 2rem
}

.btn-item-producto-common-like {
    z-index: 1;
    position: absolute;
    background-color: transparent;
    color: white;
    border-width: inherit;
    width: 25px;
    height: 25px;
}


.message-toast-cont {
    position: relative;
    width: 364px;
    height: 241px;
    padding: 4px, 0px, 14px, 0px;
    border-radius: 12px;
    opacity: 0.93px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    z-index: 9999
}

.message-toast-title {
    font-family: var(--font);
}

.message-toast-message {
    font-family: var(--fontText);
}
.message-toast-btn-close{
    position: absolute;
    top: 2px;
    right: 2px;
    color: var(--golden-font);
    background-color: transparent;
    border: none;
    width: 2em; height: 2em;
    background: transparent;
    font: inherit;
    cursor: pointer;
    
    &:before, &:after {
        position: absolute;
        top: 15%; left: calc(50% - .0625em);
        width: .125em; height: 70%;
        transform: rotate(45deg);
        background: var(--golden-font);
        content: ''
    }
    
    &:after { transform: rotate(-45deg); }
}
.message-toast-btn{
    padding: 0.5rem 5rem;
}
.message-toast-link-cont {
    display: flex;
    gap: 2em;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
}

.message-toast-link {
    font-family: var(--fontText);
}

.message-toast-link-active {
    font-family: var(--fontText);
    color: var(--golden-font)
}


@media only screen and (max-width: 600px) {
    .checkout-body {
        padding: 2rem 1rem;
        gap: 1em;
        max-width: 95vw !important;
    }

    .gradient-bg {
        padding: 0;
    }

    .form-container {
        gap: 1rem;
    }

    .producto-comprado {
        height: 120px;
    }
}

.checkout-selected-page {
    color: #bb9c1f;
    font-family: var(--fontText);
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin: 0px;
    display: inline-block;
    text-decoration: underline;
}




.checkout-unselected-page {
    color: gray;
    font-family: var(--fontText);
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin: 0px;
    display: inline-block;
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    color: rgba(76, 78, 86, 1);
    font-size: 20px;
    line-height: 21px;
    margin-top: 2px;
    font-weight: bold;
}