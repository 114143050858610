.fav-carrousel {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.74) 19.27%, #000000 100%);
    padding: 4em 0;
}

.fav-carrousel-main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 3em;
    padding: 1em 0em 3em 0em
}

.fav-carrousel-categoria-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fav-carrousel-categoria-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: invert(19%) sepia(44%) saturate(479%) hue-rotate(0deg) brightness(93%) contrast(94%);
}

.fav-carrousel-categoria-cont:hover {
    filter: invert(19%) sepia(44%) saturate(479%) hue-rotate(0deg) brightness(93%) contrast(94%);
}

.fav-carrousel-cat-title {
    font-family: var(--font);
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.001em;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
}

.cat-active {
    color: #E5CB65;
}

.h1-cabecera-favoritos-carrousel {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
    color: white;
    text-transform: uppercase;
}


@media only screen and (max-width: 600px) {
    .fav-carrousel{
        padding: 2em 1em;
    }
    .fav-carrousel-main {
        gap: 1.5em;
        padding: 1.25em 0.75em;
    }

    .h1-cabecera-favoritos-carrousel {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.002em;
        text-align: center;
    }

    .fav-carrousel-cat-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.001em;
        text-align: center;
    }
}