.btn-dorado {
  background: linear-gradient(180deg, #E5CB65 0%, #7A5D28 100%);
  border-radius: 7px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.002em;
  text-transform: uppercase;
  border-width: inherit;
  /* width: 100%; */
  /* margin: 2% 24%; */
  height: 39px;
  border-color: black;
  text-decoration: none;
  color: black;
  padding: .5rem 1rem;
}

.btn-dorado:hover {
  background: transparent;
  border: 2px solid;
  border-color: #E5CB65;
  /* Button */

  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.002em;
  text-transform: uppercase;

  /* Gradiente Dorado */

  color: #E5CB65;

}

.btn-register {
  width: fit-content;
  padding: .5rem 4rem;
  align-self: center;
  margin: 0;
  margin-top: 1rem;
}

.text-color-white {
  font:var(--fontText);
  font-size: 16px;
  color: white;
}

.text-color-gold {
  color: #E5CB65;
}

.img-carousel {
  width: 100%;
}

.background-color-gradient-black-grey {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.74) 83.33%);
}

.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* CSS MisCompras */
.title-delivery-status {
  text-align: center;
  width: initial;
}

.span-delivery-status {
  width: 60%;
  text-align: center;
  width: 60% !important;
  line-height: 22px !important;
}

.cont-delivery-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mg-top-10-px {
  margin-top: 10px;
}

.mg-top-16-px {
  margin-top: 16px;
}

.mg-top-20-px {
  margin-top: 20px;
  padding: 2px 4em;
}

.custom-agregar-carrito{
  margin-top: 20px;
  padding: 2px 4em;
  width: 80%;
}

.mg-bottom-16-px {
  margin-bottom: 16px;
}

.pd-10-px {
  padding: 10px;
}

.swiper-pagination-bullet>img {
  content: url('./icons/carousel/inactive-bullet.png');
}

.swiper-pagination-bullet>img:hover{
  content: url('./icons/carousel/active-bullet.png');
}

.swiper-pagination-bullet-active>img {
  content: url('./icons/carousel/active-bullet.png');
}

.swiper-pagination-bullet {
  opacity: inherit !important;
  background: none !important;
  display: inline !important;
}

.pagination-bullet {
  width: 19px !important;
  height: 19px !important;
}

.App-space {
  padding-top: 110px;
  padding-left: 3em;
  padding-right: 4em;
}


.Text-main-list {
  margin: 0px;
  padding: 0px;
  padding-bottom: 20px;
  list-style: none;
}

.Text-main-list-left {
  margin: 0px;
  padding: 0px;
  padding-bottom: 20px;
  list-style: none;
  text-align: left;
}

.Text-content-list {
  padding-bottom: 20px;
  text-align: left;
}

.Text-header-list-font {
  text-align: left;
  font-weight: bold;
}

.Text-header-font {
  font-weight: bold;
  padding-bottom: 10px;
}

.Text-content {
  text-align: justify;
  padding-bottom: 20px;

}

.cont-flex {
  display: flex;
}

.cont-block {
  display: block
}

.gradient-bg {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: auto;
  padding: 0rem 0rem;
  /* Gradient Oscuro 1 */
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.74) 83.33%);
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.grey-input {
  border: none !important;
  border-bottom: 1px solid grey !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.golden-input {
  border: none !important;
  border-bottom: 1px solid #E5CB65 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}



.width-100-pc {
  width: 100%;
}

.title-h2 {
  display: flex;
  font-family: var(--font);
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.1rem;
  text-transform: uppercase;
  /* text-align: left; */
}

.btn-primary {
  background: linear-gradient(180deg, #E5CB65 0%, #7A5D28 100%) ;
  border-radius: 7px ;
  font-family: 'Oswald' ;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.002em;
  text-transform: uppercase;
  border-width: inherit;
  padding: .5rem 1rem;
  height: 39px;
  /* border-color: black; */
  transition: none;
}


.btn-primary:hover {
  background: transparent ;
  border: 2px solid;
  border-color: #E5CB65 ;
  /* Button */

  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.002em;
  text-transform: uppercase;

  /* Gradiente Dorado */

  color: #E5CB65;
}
.swiper-button-prev.swiper-button-disabled{
  pointer-events: initial !important;
}
.swiper-button-next.swiper-button-disabled{
  pointer-events: initial !important;
}

@media only screen and (max-width: 400px) {
  .btn-register {
    width: fit-content;
    padding: .5rem 2rem;
    align-self: center;
  }

  .img-carousel {
    width: 99%;
  }
}