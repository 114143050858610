.carousel-cortes-container {
    height: auto;
    background-color: black;
    padding: 5rem 10%;
    position: relative;
}

.carousel-cortes-subcontainer {
    border-color: #E5CB65;
    border-style: solid;
    height: 100%;
}

.carousel-cortes-container-texto-cabecera{
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    position: absolute;
    background: black;
    padding-left: 70px;
    padding-right: 70px;
    color:white;
    transform: translate(50%,-50%);
    right: 50%;
}

.carousel-cortes-categoria-container{
    padding-top: 70px;
}

@media only screen and (max-width: 990px) {
    .carousel-cortes-container {
        height: auto;       
        padding: 72px 10px 40px 10px;
    }

    .carousel-cortes-container-texto-cabecera{
        font-size: 25px;
        line-height: 30px;
        position: absolute;
        background: black;        
        width: 326px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .carousel-cortes-container {
        padding: 5rem 7%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .carousel-cortes-container {
        padding: 5rem 13%;
    }
}

@media only screen and (min-width: 1800px) {
    .carousel-cortes-container {
        padding: 5rem 17%;
    }
}