.background-seccion-encuentranos {
  background-image: url("../../images/encuentranos.png");
  /* background: linear-gradient(180deg, #000000 3.73%, rgba(0, 0, 0, 0) 40.99%); */
  /*340pz height*/
  height: 340px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  background-position: bottom;
  position: relative;
}

.gradient-div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 150px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 95%);
}

.swipper-mapa-carrusel .swiper-wrapper{
  align-items: flex-start;
}

#swipper-mapa .image-swiper-mapa-button-next {
  background: url('../../icons/carousel/chevron-back.png');
  transform: rotate(180deg);
}

#swipper-mapa .image-swiper-mapa-button {
  width: 32px;
  height: 32px;
  background-color: transparent;
  color: transparent;
}

#swipper-mapa .image-swiper-mapa-button-prev {
  background: url('../../icons/carousel/chevron-back.png');
}

@media only screen and (max-width: 600px) {
  .background-seccion-encuentranos {
    background-image: url("../../images/BG-ENCUENTRANOS-MOBILE.png");
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    background-position: center;
    background-size: cover;
  }
}