.div-img-item-producto-common {
    position: relative;
    width: fit-content;
    margin: auto;
}

.img-item-producto-common {
    border-radius: 5%;
    margin: 1%;
    max-width: 327px;
}

.btn-item-producto-common-like {
    top: calc(15px + 1%);
    left: calc(15px + 1%);
    z-index: 1;
    position: absolute;
    background-color: transparent;
    color: white;
    border-width: inherit;
    width: 25px;
    height: 25px;
}

.btn-item-producto-common-menos-mas {
    background-color: transparent;
    color: #E5CB65;
    font-size: 32px;
    display: contents;
}

.btn-item-producto-common-agregar-item {
    margin: 0px !important;
    width: 100%;
}

.div-item-producto-common-agregar-item {
    margin-left: 40px;
    margin-right: 40px;
}

.span-item-producto-common-img-descripcion {
    color: WHITE;
    position: absolute;
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    transform: translate(50%, -50%);
    bottom: -10px;
    right: 50%;
    inline-size: max-content;
}

.span-item-producto-common-contador {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

.div-item-producto-common-precio {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 25px;
    margin-top: 20px;
}

.body-copy {
    font-style: var(--fontText);
    font-size: 16px;
    text-transform: lowercase;
    color: white;
}

.div-item-producto-common-peso-aprox {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}

.text-item-producto-common-precio {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.001em;
    text-align: left;
}

.text-item-producto-common-peso {
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
}

.div-item-producto-block {
    margin: auto;
    max-width: 372px;
}


/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {

    .div-img-item-producto-common {
        width: 100%;
    }

    .img-item-producto-common {
        border-radius: 5%;
        margin: 1%;
        max-width: 90vw;
        width: 85vw;
    }

    .span-item-producto-common-img-descripcion {
        color: var(--light-font);
        text-align: center;
        font-family: var(--font);
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0.06px;
        text-transform: uppercase;
    }

    .div-item-producto-block {
        max-width: 100vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .div-item-producto-common-precio {
        width: 75vw;
    }


    .div-item-producto-common-agregar-item {
        width: 75vw;
    }

    .btn-item-producto-common-agregar-item {
        width: 85%;
    }

    .text-item-producto-common-precio {
        color: var(--light-font);
        font-family: var(--font);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.02px;
        text-transform: uppercase;
    }

    .body-copy {
        color: var(--light-font);
        font-family: var(--fontText);
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
    }
}