/* Diseño MOBILE CART PANEL */
.cart-panel {
    padding: 4rem 3rem;
    position: fixed;
    /* width: 35vw; */
    top: 0;
    right: 0;
    z-index: 999;
    height: 100vh;
    background-color: #ffff;
    background-position: center;
    background-size: contain;
    /* background-size: cover; */
    background-repeat: no-repeat;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    opacity: 1;
    overflow: auto;
    min-width: 35vw;
}

.cart-panel.open {
    transform: translateX(0);
}

.cart-top-cont {
    justify-content: space-between;
    padding: 1rem 0rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid black;
}

.cart-panel-total {
    font-family: var(--font);
    font-weight: 500;
    font-size: 1.5rem;
    /* font-kerning: 1; */
    text-transform: uppercase;
    margin: auto;
}


/* --------- CART ITEM NAVBAR -------- CSS */

.card-item-navbar {
    margin: 1rem 0rem
}

.card-item-navbar-left {
    height: 160px;
}


/* Contenedor de la imagen - IZQUIERDA */

.card-item-navbar-img-cont {
    padding: 0;
    /* border-radius: 5px; */
}

.item-card-navbar-img {
    border-radius: 7px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 160px;
}

/* Contenedor de la info - CENTRO */
.card-item-navbar-center {
    padding: 0.5rem .5rem;
    padding-left: 1rem;
    /* background-color: red; */
    margin: 0;
}

.item-card-navbar-title {
    font-family: var(--font);
    text-align: left;
}

.item-card-navbar-weight {
    font-family: var(--fontText);
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
}

.item-card-navbar-counter>button {
    font-size: 2rem;
    display: flex;
    height: 100%;
    justify-content: center;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
}

.item-card-navbar-counter>p {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font);
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

/* Contendor del total - DERECHA */
.card-item-navbar-right {
    padding:0;
    margin:0;
    /* display: flex; */
    flex-direction: column;
    justify-content: flex-end;
}

.card-item-navbar-right>button {
    /* font-size: 2rem; */
    display: flex;
    /* height: 100%; */
    justify-content: center;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font-weight: 600;
    color: var(--red-font);
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .cart-panel {
        width: 100vw;
        padding: 2rem 1.5rem
    }

    /* --------- CART ITEM NAVBAR -------- CSS */

    .card-item-navbar-left {
        height: 150px;
    }

    .item-card-navbar-img {
        height: 100px;
    }
}

.closed-button {
    position: relative;
    border: none;
    width: 2em; height: 2em;
    background: transparent;
    font: inherit;
    cursor: pointer;
    
    &:before, &:after {
        position: absolute;
        top: 15%; left: calc(50% - .0625em);
        width: .125em; height: 70%;
        transform: rotate(45deg);
        background: black;
        content: ''
    }
    
    &:after { transform: rotate(-45deg); }
}