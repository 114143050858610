.pedido-info-cont{
    text-align: initial;
}

.id-pedido {
    font-size: 20px;
    color: gray;
    font-weight: 300;
    line-height: 14px;
    margin: 1rem 0;
    padding: 0;
    width: auto;
    font-family: var(--fontText);
    text-transform: none;
}

.my-account-buttons {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 2rem 0;
    padding-bottom: 3rem;
    border: none
}

.my-account-buttons>.btn-cont {
    max-width: 120px;
    height: auto;
}

.btn-cont>a {
    text-decoration: none;
}

.btn-title {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-family: var(--font);
    text-decoration: none;
}

.selected {
    color: var(--golden-font);
}

.default {
    color: var(--light-font);
    opacity: 0.6;
}

/* Delivery Status Section */
.delivery-status {
    align-items: center;
    justify-content: center;
    gap: 2rem
}

.status-cont {
    justify-content: center;
    align-items: center;
    flex: initial;
}

.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    /* background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(45deg, #E5CB65, #7A5D28) border-box;
    border: 6px solid transparent; */
    border: 6px solid transparent;
    border-radius: 50%;
    padding: 1rem;
}


.status-active {
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(45deg, #E5CB65, #7A5D28) border-box;
}

.status-default {
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(45deg, gray, gray) border-box;

    opacity: 0.5;
}

.status-img {
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: 100%;
}

.status-text {
    margin: 1rem 0;
    width: 50%;
    text-transform: uppercase;
    font-family: var(--font);
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.2rem;
}


.caret-cont {
    width: fit-content;
    align-items: center;
    justify-content: center;
    flex: initial;
}

.caret-text {
    margin-bottom: 100%;
    text-transform: uppercase;
    font-family: var(--fontText);
    /* font-weight: 500; */
    font-size: 2rem;
    line-height: 1.2rem;
}

.div-cabecera {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding-top: 40px;
    margin-bottom: 56px;
}

.h1-cabecera {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
    color: white;
    text-transform: uppercase;
}

.text-cabecera {
    color: white;
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
}

@media only screen and (max-width: 993px) {
    .svg-container {
        width: 150px;
        height: 150px;
    }

    .status-text {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .svg-container {
        width: 100px;
        height: 100px;
    }

    .status-text {
        width: 100%;
    }

    .delivery-status {
        gap: .5rem
    }

    .caret-text {
        display: flex;
        font-size: 1.5rem;
    }

    .title-h2 {
        text-align: left;
        font-size: 1.3rem;
        line-height: 1.5rem;
    }

    .checkout-body {
        padding: 1rem
    }

}


@media only screen and (max-width: 400px) {
    .my-account-buttons>.btn-cont {
        max-width: 100px;
    }

    .btn-title {
        font-size: 1rem;
    }

    .btn-img {
        width: 100%;
        background-color: red;
    }

    a>img {
        width: 100%;
    }

    .delivery-status {
        flex-direction: column;
    }

    .caret-cont {
        width: 100%;
    }

    .caret-text {
        margin: 0;
    }

    .caret-text>* {
        transform: rotate(90deg)
    }

    .status-cont {
        flex-direction: row;
        gap: 1em
    }

    .status-text {
        width: 50%;
    }

    .svg-container {
        border: 4px solid transparent
    }

    .id-pedido{
        font-size: 17px;
        line-height: 19px;
        margin: 0.5rem 0;
    }
}