.hero-calltoaction-container {
    position: absolute;
    top: 57%;
    left: 10%;
    width: fit-content;
    text-align: left;
    width: 20%;
}

.hero-calltoaction-container>a>button {
    width: 65%;
}

.triangulo-img {
    position: absolute;
    top: -1px;
}

.custom-sub-footer-home {
    background-image: url("../../images/sub-footer-home.svg");
    /* position: relative;
    display: flex;
    justify-content: center; */
    width: 100%;
    min-height: 615px;
    margin: 0px !important;
    align-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 100px;
    padding-bottom: 35px;
}

.custom-div-sub-footer-home {
    padding: 120px 100px;
    /* width: 100%;
    margin: 6% 6%;   
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: nowrap; */
}

.custom-div-sub-footer-home-titulo {
    color: white;
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
}

.custom-div-sub-footer-home-texto {
    background: rgba(150, 150, 150, 0.6);
    /* width: 30%;    */
    border-radius: 10px;
    /* background-color: gray; */
}

.custom-div-sub-footer-home-texto-div {
    padding: 34px 45px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.texto-blanco {
    color: white;
}

.custom-div-sub-footer-home-div-texto {
    color: white;
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.002em;
    text-align: left;
}

.hr-text {
    width: 100%;
    color: #E5CB65;
    margin: 20px 60px;
    border-top: 2px solid;
    opacity: 1 !important;
}

.background-gradient-seccion-parrilla {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.74) 19.27%, #000000 100%);
    height: 699px;
}

.background-seccion-subcarousel {
    background: #000000;
    height: 853px;
}



.background-seccion-validacion-cp {
    background-image: url("../../images/bg-codigo-postal.jpg");
    background-size: cover;
    height: 31.77vw;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    overflow: hidden;
}

.cont-seccion-validacion-cp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    z-index: 1;
}

.cont-seccion-validacion-cp>.cp-main-container {
    align-self: center;
    /* width: 450px; */
}

/* .cont-seccion-validacion-cp>.cp-main-container>.validate-btn {
    padding: .5rem 2rem;
} */

.cont-seccion-validacion-cp>.cp-main-container>.cp-input-container>input {
    background-color: transparent;
    width: 400px;
}

.div-i-btn-validacion {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
}

.h1-validacion {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
}

.i-validacion {
    width: 200%;
    order: 1.5px solid #E5CB65;
    transform: rotate(0.09deg);
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
}

.txt-validacion {
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    width: 500px;
}

.custom-div-sub-footer-home-btn {
    margin-top: 40px;
    /* padding: 0px 99px; */
    width: 100%;
}

.custom-div-sub-footer-home-btn-dorado {
    margin: 0px !important;
    width: 55.8%;
}

.custom-div-logo-desc {
    text-align: right;
    margin-top: 80px;
}

.custom-whatsapp-icon {
    width: 50px;
    height: 50px;
}

/* .whatsapp-container{
    position: absolute;
    width: 100%;
    height: 100%;
} */

.custom-position-whatsapp-icon {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 18px;
    z-index: 2;
}

.custom-position-whatsapp-icon-under {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    font-size: 18px;
    z-index: 2;
}

.custom-position-title-carrusel {
    position: absolute;
    top: 40%;
    left: 10%;
    width: 575px;
    text-align: left;
}

.custom-title-carrusel-home {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    /* or 111% */

    letter-spacing: 0.2px;
    text-transform: uppercase;
}

.carrusel-container-home {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.img-carousel-home {
    min-height: unset;
    background: linear-gradient(180.09deg, rgba(0, 0, 0, 0.4) 17.19%, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.5) 56.51%, #000000 90.97%);
}

.btn-carrusel-home {
    width: 45%;
    margin-left: 20%;
    margin: 5% 0% 0% 0%;
}

.bg-quien-es-keken-container {
    margin: auto;
}

/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {

    .custom-div-sub-footer-home-texto {
        margin-left: 20px;
        margin-right: 20px;
    }

    .background-seccion-validacion-cp {
        background-image: url("../../images/validacion-background-mobile.png");
        background-color: #EEEEEF;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        overflow: hidden;
    }

    .custom-div-sub-footer-home-texto-div {
        margin-top: 42px;
    }

    .custom-validacion {
        margin: 20px;
        text-align: start;
    }

    .h1-validacion {
        font-size: 25px;
    }

    .txt-validacion {
        text-align: start;
    }

    .custom-div-sub-footer-home-texto-div {
        padding-left: 11px;
        padding-right: 11px;
        padding-top: 26px;
    }

    .custom-div-logo-desc {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .custom-title-carrusel-home {
        font-family: var(--font);
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5)
    }

    .custom-position-title-carrusel {
        position: absolute;
        bottom: 110px;
        left: 10vw;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .btn-carrusel-home {
        width: 50%;
        margin: 5% 0% 0% 0%;
    }

    .custom-sub-footer-home {
        padding: 10px 0px;
    }

    .txt-validacion {
        width: 90% !important;
        text-align: left;
    }

    .cont-seccion-validacion-cp>.cp-main-container {
        width: 90%;
    }

    .cont-seccion-validacion-cp>.cp-main-container>.cp-input-container>input {
        width: auto;
    }

    .home-hero-img-container {
        text-align: center;
        position: relative;
        overflow: hidden;
    }

    .home-hero-img-container::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 120px;
        /* Altura del fadeout */
        background: linear-gradient(transparent, rgba(0, 0, 0, 1));
    }

    .hero-btn-container {
        text-align: center;
    }

    .hero-calltoaction-container {
        top: unset;
        bottom: 50px;
        text-align: center;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        left: unset;
        margin-top: 3rem;
    }

    .bg-quien-es-keken-container {
        margin: 2em 0;
    }
    
    .img-fluid {
        max-width: 80%;
    }

    .custom-sub-footer-home {
        background-position: center;
    }

    .img-carousel-home {
        width: 100% !important;
    }
    
    .hero-calltoaction-container>a>button{
        width: 100%;
    }

}

@media only screen and (max-width: 1150px) and (min-width:600px) {

    .custom-div-sub-footer-home-texto-div {
        margin-top: 42px;
    }

    .custom-title-carrusel-home {
        font-family: var(--font);
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
    }

    .custom-position-title-carrusel {
        position: absolute;
        top: 50%;
        left: 60px;
        width: 300px;
    }

    .hero-calltoaction-container {
        left: 60px;
        top: calc(50% + 65px);
    }

}


@media only screen and (max-width: 1600px) and (min-width:1150px) {
    .hero-calltoaction-container {
        top: 62%;
    }
}