.cabecera-producto-img {
    /* max-width: 1280px; */
    height: 346px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cabecera-producto-titulo {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
}

.cabecera-producto-descripcion {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
}

.div-producto-productos {
    padding: 40px 90px 40px 90px;
}

.div-producto-item-padding {
    padding: 10px 10px 50px 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}


.tooltip-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tooltip-sub-container {
    align-self: center;
    width: 40%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem
}

.tooltip-sub-container>span {
    height: fit-content;
    width: auto;
    padding: 5px;
    font-size: 29px;
    /* font-weight: 600; */
    line-height: 19px;
}

.tooltip-sub-container>p {
    font-family: var(--fontText);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.002em;
    text-align: left;
    margin: 0
}

@media only screen and (max-width: 600px) {
    .tooltip-sub-container {
        width: 100%;
    }

    .tooltip-sub-container {
        width: 90%;
        margin-bottom: 3rem;
    }

    .div-producto-productos {
        padding: 0;
    }

    .row-products-container {
        margin: 0 !important
    }

}

@media only screen and (min-device-width : 600px) and (max-device-width : 1024px) {
    .tooltip-sub-container {
        width: 60%
    }
}

@media only screen and (min-width : 1824px) {
    .tooltip-sub-container {
        width: 30%
    }
}