.not-editando{
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.data-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.data-container p {
    font-family: var(--fontText);
    font-size: 18px;
    margin-right: 10px;
    width:50%;
}

@media only screen and (max-width: 600px) {
    .data-container{
        flex-direction: column;
        margin-bottom: 0;
    }
    .not-editando{
        padding-top: 0;
    }
    .data-container>p{
        width:100%;
    }
}