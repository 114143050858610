.background-cross-sell-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.74) 19.27%, #000000 100%);
    height: auto;
}

.background-cross-sell-img {
    background-color: #000000;
    background-image: url("../../images/producto/bg-cross-sell.jpg");
    height: 40vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.div-cabecera-cross-sell {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding-top: 40px;
    margin-bottom: 56px;
}

.h1-cabecera-cross-sell {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
    color: white;
    text-transform: uppercase;
}

.text-cabecera-cross-sell {
    color: white;
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.swiper-cross-sell {
    width: 60%
}

.image-swiper-button-next {
    background: url('../../icons/carousel/chevron-back.png');
    transform: rotate(180deg);
}

.image-swiper-button {
    width: 32px;
    height: 32px;
    background-color: transparent;
    color: transparent;
}

.image-swiper-button-prev {
    background: url('../../icons/carousel/chevron-back.png');
}

.div-items-cross-sell {
    display: flex;
    justify-content: space-around;
    /* padding-left: 10%;
    padding-right: 10%; */
    flex-wrap: nowrap;
    flex-direction: row;
}

.cont-btn-cross-sell {
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.verMasLink {
    font-family: var(--fontText);
    font-weight: 500;
    /*Semibold*/
    font-size: 16pt;
    text-decoration: underline !important;
}

.verMasLink:hover {
    color: #E5CB65 !important
}

.cont-btn-cross-sell .verMasLink:hover {
    color: #E5CB65;
    text-decoration: underline !important;
}

.cont-btn-cross-sell>* {
    color: var(--light-font);
}

.image-swiper-cross-sell-button-next {
    background: url('../../icons/carousel/chevron-back.png');
    transform: rotate(180deg);
    display: none;
}

.image-swiper-cross-sell-button {
    width: 32px;
    height: 32px;
    background-color: transparent;
    color: transparent;
    display: none;
}

.image-swiper-cross-sell-button-prev {
    background: url('../../icons/carousel/chevron-back.png');
    display: none;
}

@media only screen and (min-width: 1700px) and (max-width: 1800px) {
    .background-cross-sell-img {
        height: 42vw
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
    .background-cross-sell-img {
        height: 44vw
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
    .swiper-cross-sell {
        width: 75%
    }
    .background-cross-sell-img {
        height: 47vw
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {
    .swiper-cross-sell {
        width: 80%
    }
    .background-cross-sell-img {
        height: 49vw
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .swiper-cross-sell {
        width: 90%
    }
    .background-cross-sell-img {
        height: 54vw
    }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .swiper-cross-sell {
        width: 95%
    }
    .background-cross-sell-img {
        height: 55.41vw
    }
}

@media only screen and (max-width: 600px) {

    .background-cross-sell-img {
        height: auto;
    }

    .text-cabecera-cross-sell {
        width: 90vw;
    }

    .h1-cabecera-cross-sell {
        width: 90vw;
        margin: 1rem 0;
    }

    .div-cabecera-cross-sell {
        margin-bottom: 20px;
    }

    .swiper-cross-sell {
        width: 100%
    }

    .image-swiper-cross-sell-button-next {
        background: url('../../icons/carousel/chevron-back.png');
        transform: rotate(180deg);
        display: block;
    }

    .image-swiper-cross-sell-button {
        width: 32px;
        height: 32px;
        top: 35%;
        background-color: transparent;
        color: transparent;
        display: block;
    }

    .image-swiper-cross-sell-button-prev {
        background: url('../../icons/carousel/chevron-back.png');
        display: block;
    }
}
