.SwiperSlide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.swiper-categoria {
    padding: 1em 3em;
    margin-left: 7vw;
    margin-right: 7vw;
}

.swiper-categoria .swiper-wrapper{
    align-items: flex-end;
}

.carousel-categoria-item {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-end;
    filter: invert(40%) sepia(13%) saturate(333%) hue-rotate(190deg) brightness(92%) contrast(87%);
}

.carousel-categoria-item>span {
    font-family: var(--font);
}

.carousel-selected-item>span {
    font-family: var(--font);
}

.carousel-selected-item{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-end;
    filter: invert(19%) sepia(44%) saturate(479%) hue-rotate(0deg) brightness(93%) contrast(94%);
}

.carousel-categoria-item:hover {
    filter: invert(19%) sepia(44%) saturate(479%) hue-rotate(0deg) brightness(93%) contrast(94%);
}

.carousel-categoria-item> img {
    max-width: 120px;
}

.carousel-selected-item> img {
    max-width: 120px;
}

.carousel-categoria-container-nav-button {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 120px;
}

.carousel-categoria-nav-button {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border-width: inherit;
}

.inner-carousel-categoria-container {
    margin: 2%;
}

.inner-carousel-categoria-imagen {
    border-radius: 5px 5px 5px 5px;
    max-width: 740px;
    max-height: 447px;
}

.inner-carousel-categoria-div-imagen {
    padding: 40px;
    flex: 0.7;
}

.inner-carousel-categoria-div-texto {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    text-align: left;
    padding-top: 40px;
    margin-right: 40px;
    flex: 0.3;
}

.inner-carousel-categoria-div-texto-titulo {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.002em;
    text-transform: uppercase;
}

.inner-carousel-categoria-div-texto-descripcion {
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.002em;
}

.inner-carousel-categoria-div-precio-agregar {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 52px;
}

.inner-carousel-categoria-button-menos-mas {
    background-color: transparent;
    color: #E5CB65;
    border-width: inherit;
    width: 32px;
    height: 32px;
}

.inner-carousel-categoria-texto-precio {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
}

.inner-carousel-categoria-icon-info  {
    width: 24px;
    height: 24px;
    margin: 4px;
}

.inner-carousel-categoria-texto-aprox-kilo  {
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.image-swiper-button-next {
    background: url('../../icons/carousel/chevron-back.png');
    transform: rotate(180deg);
}

.image-swiper-button {
    width: 32px;
    height: 32px;
    background-color: transparent;
    color: transparent;
}

.image-swiper-button-prev {
    background: url('../../icons/carousel/chevron-back.png');
}

.image-swiper-button-prev {
    background: url('../../icons/carousel/chevron-back.png');
}


@media only screen and (max-width: 990px) {
    .carousel-cortes-container {
        height: auto;
        height: auto;
        padding: 72px 10px 40px 10px;
    }

    .carousel-cortes-container-texto-cabecera {
        font-size: 25px;
        line-height: 30px;
        position: absolute;
        background: black;
        background: black;
        width: 326px;
    }

    .inner-carousel-categoria-container {
        margin: 5%;
    }

    .swiper-categoria {
        padding: 1em 3em;
    }
}