@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{
  --font: 'Oswald', sans-serif;
  --fontText: 'Source Sans Pro', sans-serif;
  --light-font: #fff;
  --golden-font: #E5CB65;
  --golden: #E5CB65;
  --red-font: #E74536;
  --btn-red:#E74536;
  --dark-font: rgb(26, 26, 26);
  --white-bg: #EDEDEE;
  --hoverButton: rgba(255, 255, 255, 0.274);
}

input[type="checkbox"] {
  position: relative; /* Asegura que los pseudo-elementos estén posicionados relativos a este contenedor */
  border: 2px solid #000; /* Grosor y color del borde */
  border-radius: 5px; /* Radio de las esquinas redondeadas */
  /* Otros estilos opcionales */
  width: 17px; /* Ancho del checkbox */
  height: 17px; /* Altura del checkbox */
  box-shadow: none;
  /* Agrega aquí otros estilos que desees */
}

.form-check-input {
  position: relative !important; /* Asegura que los pseudo-elementos estén posicionados relativos a este contenedor */
  border: 2px solid #000 !important; /* Grosor y color del borde */
  border-radius: 5px !important; /* Radio de las esquinas redondeadas */
  /* Otros estilos opcionales */
  width: 17px !important; /* Ancho del checkbox */
  height: 17px !important; /* Altura del checkbox */
  box-shadow: none !important;
  /* Agrega aquí otros estilos que desees */
}

input[type="checkbox"]::before {
  position: relative; /* Asegura que los pseudo-elementos estén posicionados relativos a este contenedor */
  border: 2px solid #000; /* Grosor y color del borde */
  border-radius: 5px; /* Radio de las esquinas redondeadas */
  /* Otros estilos opcionales */
  width: 17px; /* Ancho del checkbox */
  height: 17px; /* Altura del checkbox */
  box-shadow: none;
  /* Agrega aquí otros estilos que desees */
}

input[type="checkbox"]:hover{
  box-shadow: none;
}

input[type="checkbox"]:hover::after{
  box-shadow: none;
}

input[type="checkbox"]:hover::before{
  box-shadow: none;
}


input[type="checkbox"]:checked::after {
  content: ''; /* Asegura que el pseudo-elemento tenga contenido */
  position: absolute; /* Posiciona el pseudo-elemento absolutamente */
  top: -6px; /* Ajusta la posición vertical para ocultar la esquina superior derecha */
  right: -2px; /* Ajusta la posición horizontal para ocultar la esquina superior derecha */
  width: 5px; /* Ancho del cuadrado blanco */
  height: 11px; /* Altura del cuadrado blanco */
  background-color: #fff; /* Color del cuadrado blanco */
  box-shadow: none;
  border-color: black;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

input[type="checkbox"]:checked {
  background-color: white;
  box-shadow: none;
  border-color: #000;
}