.footer-img {
    cursor: pointer;
    /* width: auto; */
    /* height: 90%; */
    width: 100px;
    padding: 5px;
}

/* .footer-img:hover {
    background-color: var(--hoverButton);
    border-radius: 3px;
} */

.custom-footer {
    background-image: url("../../images/Fondofooter.svg");
    height: fit-content;
    position: static;
    height: fit-content;
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font);
    padding: 2em 0em;
    padding: 2em 0em;
}

.footer-link {
    color: white;
    margin: 0;
    font-weight: bold;
    align-self: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.footer-link:hover {
    color: #E5CB65;
}

.footer-text {
    color: white;
    /* font-size: 80%; */
    font-family: var(--font);
    font-size: 11px;
    /* font-weight: 600; */
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

}





.footer-text-content {
    position: static;
    position: static;
    bottom: 10%;
    text-align: center;
}


.footer-text-content>label {
    color: #FFF;

    /* Input Md */
    font-family: var(--fontText);
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    /* 109.091% */
}


.footer-text-content>label {
    color: #FFF;

    /* Input Md */
    font-family: var(--fontText);
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    /* 109.091% */
}

.footer-navigation-content {
    position: static;
    position: static;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em
}

.footer-button-icon {
    padding: 0;
    margin: 0;
    align-self: center;
    width: 40px;
    height: 40px;
    margin: 1%;
    background: transparent;
    border: none;
    cursor: pointer;
}

.footer-button-icon:hover {
    background-color: var(--hoverButton);
    border-radius: 3px;
}


/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {

    .custom-footer {
        flex-direction: column;
        height: auto;
        gap: 0;
        align-items: center;
        align-items: center;
    }

    .footer-navigation-content {
        flex-direction: column;
        height: fit-content;
        position: static;
        transform: none;
        padding: 0;
        padding-top: 0;
        margin-top: 30px;
        gap: 1rem
    }

    .footer-button-icon {


        text-align: center;

    }

    .footer-img {
        width: 30vw;
        /* margin-bottom: 2rem; */
        /* margin-bottom: 2rem; */
    }

    .footer-text-content {
        position: static;
        /* margin-bottom: 1rem; */
        margin-top: 2rem;
    }

    .footer-text-content>label {
        margin-bottom: 1rem;
    }

    .footer-text-content>label {
        /* padding-bottom: 1rem; */
        color: #FFF;
        text-align: center;

        /* Input Md */
        font-family: var(--fontText);
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        /* 109.091% */
    }

    .footer-line {
        position: static;
        border-color: white;
        width: 90vw;
        margin: 2.5rem 0rem 0.5rem 0rem;
        opacity: 1;
        color: #FFF;
        text-align: center;

        /* Input Md */
        font-family: var(--fontText);
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        /* 109.091% */
    }

    .footer-line {
        position: static;
        border-color: white;
        width: 90vw;
        margin: 2.5rem 0rem 0.5rem 0rem;
        opacity: 1;
    }
}