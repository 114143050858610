.div-pdp-container {
    /* padding: 140px 100px 40px 100px; */
    background-color: black;
}

.pdp-carr-cont {
    padding: 140px 100px 40px 100px;
}

@media only screen and (max-width: 600px) {

    .div-pdp-container {
        /* padding: 1em; */
        padding-top: 110px
    }

    .pdp-carr-cont {
        padding: 1em
    }

}