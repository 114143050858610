.mapa-item-div-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.mapa-item-div-container:hover h3,
.mapa-item-div-container:hover li {
    color: var(--golden-font);
}

.mapa-item-img {
    /* max-width: 120px; */
    margin-bottom: 5px;
}

.mapa-item-titulo {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}
.mapa-item-text-align {
    text-align: left;
    width: 120px;
}

.mapa-item-sub-listado {
    font-family: var(--fontText);
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.002em;
}

.mapa-item-sub-listado ul{
    list-style-type: disc; /* Add bullet points to each city */
    margin-left: 20px; /* Adjust margin as needed */
}

.h1-encuentranos {
    font-family: var(--font);
    font-weight: 550; /* Semibold */
    color: white;
    position: inherit;
}

.itemsAlign {
    justify-content: center;
}