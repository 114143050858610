.div-acompañamiento-acompañamientos {
    padding: 40px 90px;
}

.div-acompañamiento-item-padding {
    padding: 10px 10px 50px 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}


/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {
    .div-acompañamiento-acompañamientos {
        padding: 0px;
    }

    .row-products-container {
        margin: 0 !important
    }
}