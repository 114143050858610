.btn-item-producto-common-like-pdp {
    top: 15px;
    left: 25px;
    z-index: 1;
    position: absolute;
    background-color: transparent;
    color: white;
    border-width: inherit;
    font-size: 20px;
}

.it-prod-desc-img{
    border-radius: 10px;
    max-width: 98%;
    /* margin: 1%; */
}

.it-prod-desc-d-t-cont-titulo{
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.002em;
    text-transform: uppercase;
}

.it-prod-desc-d-t-container{
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    height: 100%;
}

.it-prod-desc-d-t-pes-pre{
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
}

.it-prod-desc-d-t-pes-cont{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2%;
}

.it-prod-desc-d-t-i-info{
    width: 24px;
    height: 24px;
}

.it-prod-desc-d-t-i-info-cont{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.it-prod-desc-d-t-cont-descripcion{
    margin-top: 20px;
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.002em;
}

.it-prod-desc-d-t-cont-acc{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3em;
}

.it-prod-handler-container{
    font-family: var(--font);
    font-size: 16px;
    font-weight: 500;
}

.it-prod-desc-d-t-btn-mas-men{
    background-color: transparent;
    background-color: transparent;
    background-color: transparent;
    color: #E5CB65;
    border-width: inherit;
    font-size: 32px;
    /* width: 32px;
    height: 32px; */
}

.it-prod-desc-d-t-pre{
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
}


/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {
    .it-prod-desc-d-t-cont-titulo{
        font-family: var(--font);
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 35px;
        letter-spacing: 0.002em;
        text-transform: uppercase;
        text-align: center;
    }
}


.it-prod-desc-img {
    border-radius: 10px;
    /* margin: 1%; */
}

.it-prod-desc-d-t-cont-titulo{
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.002em;
    text-transform: uppercase;
}

.it-prod-desc-d-t-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    height: 100%;
}

.it-prod-desc-d-t-pes-pre {
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
}

.it-prod-desc-d-t-pes-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2%;
}

.it-prod-desc-d-t-i-info {
    width: 24px;
    height: 24px;
}

.it-prod-desc-d-t-i-info-cont {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.it-prod-desc-d-t-cont-descripcion {
    margin-top: 20px;
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.002em;
}

.it-prod-desc-d-t-cont-acc {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: center;
    margin-top: 3em;
}

.it-prod-handler-container {
    font-family: var(--font);
    font-size: 16px;
    font-weight: 500;
}

.it-prod-desc-d-t-btn-mas-men {
    background-color: transparent;
    background-color: transparent;
    background-color: transparent;
    color: #E5CB65;
    border-width: inherit;
    font-size: 32px;
    /* width: 32px;
    height: 32px; */
}

.it-prod-desc-d-t-pre {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
}


@media only screen and (max-width: 990px) {
    .it-prod-desc-img {
        border-radius: 10px;
        /* margin: 1%; */
    }

    .contador-categorias {
        font-family: var(--font);
        font-size: 16px;
    }

    .it-prod-handler-container {
        display: flex;
        align-items: center;
    }

    .it-prod-desc-d-t-btn-mas-men {
        font-size: 32px;
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0
    }

    .it-prod-desc-d-t-cont-titulo {
        text-align: center;
        color: var(--light-font);
        margin-top: 1em;
    }

    .btn-carousel-categoria{
        width: 85%
    }

    .it-prod-desc-img{
        border-radius: 10px;
        /* margin: 1%; */
    }
}
