.delivery-info {
    margin-bottom: 2rem;
}

.title-h3 {
    display: flex;
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    /* text-align: left; */
}


.title-description {
    font-family: var(--fontText);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    /* margin-top: 1rem;
    margin-bottom: 2.5rem; */
}


.address-row {
    padding: 0;
    margin:1rem 0;
}
.address-row-default{
    margin-top: 20px;
    padding: 0;
    margin:1rem 0;
    text-align: left;
    padding-left: 20px;
}

.info-title{
    margin-right: 5px;
}

.address-input {
    display: flex;
    width: 70%;
    padding: .5rem 1rem;
    margin: .5rem 0;
}

.address-input-error{
    display: flex;
    padding: .5rem 1rem;
    margin: .5rem 0;
    color: #dc3545;
    font-size: .875em;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.address-input>span {
    color: #4C4E56;
    margin-right: .25rem;
    font-weight: 600;
}

.cp-main-container {
    width: fit-content;
    align-items: flex-start;
    /* margin-top: 2rem; */
}

.cp-input-container {
    width: fit-content;
    align-items: flex-start;
}

.validate-input {
    display: flex;
    width: fit-content;
    padding: .5rem 1rem;
    margin: .5rem 0;
}

.input-feedback {
    text-align: left !important
}

.input-radio {
    width: 50%;

}

.validate-btn {
    width: fit-content;
    padding: .25rem 1rem;
    margin: 0;
    width: 10vw;
}


.btn-submit {
    width: fit-content;
    padding: .5rem 3rem;
    margin: 0;
}

.warning-container {
    background-color: #F3D8DA;
    align-items: center;
    /* justify-content:center; */
    padding: 21px 15px;
    gap: 14px;
    border-radius: 10px;
    margin: 0;
    margin-bottom: 1rem;
}

.warning-container>p {
    font-weight: 600;
    color: var(--red-font);
    margin: 0;
}

.warning-container>span {
    font-weight: 600;
    height: fit-content;
    padding: 0;
    margin: 0.25rem 0.5rem;
    transform: scale(1.5);
    display: flex;
    align-items: center;
    justify-content: center;
}


.delete-dir-btn{
    text-decoration: underline;
    color: var(--btn-red);
    border: none;
    background-color: transparent;
    width: fit-content;
    align-self: center;
}
.icon-btn-cont{
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: flex-start;
}
.icon-btn{
    border: none;
    background-color: transparent;
    margin-left: .5rem;
    color: #4C4E56;
    font-size: 1.5rem;
    transform: translateY(-6px);

}
.submit-cp-btn{
    margin-top:10px;
}

@media only screen and (max-width: 600px) {
    .address-input {
        width: 100%;
    }

    .address-row-default{
        margin: 0;
    }

    .address-row{
        margin: 0;
    }

    .stack-horizontal,
    .payment-options {
        display: flex;
        flex-direction: column;
        gap: 0.25rem !important;
        align-items: self-start;
        flex-flow: column-reverse;
    }

    .title-description {
        margin-bottom: 1rem;
    }

    .warning-container {
        align-items: center;
        flex-flow: row;
    }

    .warning-container>p {
        flex: 0.8;
        text-decoration: left;
    }

    .warning-container>span {
        flex: 0.2;
        margin: 0;
        transform: scale(2);
    }

    .cp-main-container {
        width: 100%;
    }

    .validate-btn {
        padding: 0.5rem 0rem; 
        width: 22.5vw;
    }

    
    ::placeholder{
        font-family: var(--fontText);
        font-size: 18px;
        color: #4C4E56;
        opacity: 70%;
    }

    .validate-input{
        width: 100%;
    }

    .submit-cp-btn{
        align-self: flex-start;
        margin-top: 1em;
    }
}