.custom-navbar {
    height: 90px;
    display: flex;
    justify-content: space-evenly;
    background-color: RGBA(0, 0, 0, 0.8);
    opacity: 1;
    /* margin-left: 1%;
    margin-right: 1%; */
    /* justify-content: center; */
    /* width: 98%; */
    border-radius: 10px;
    align-items: center;
    position: fixed;
    z-index: 2;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    width: 97%;
    margin: 0% 1.5%;
}

.btn-icon-container {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    gap: 0.5rem
}

.width-10-pc {
    width: 10%;
}

.navbar-button {
    color: white;
    margin: 2%;
    font-weight: bold;
    align-self: center;
    text-decoration: none;
    font-family: var(--font);
    font-weight: 500;
    font-size: 23px;
    line-height: 25px;
}

.navbar-button:hover {
    color: #E5CB65 !important;
}

.navbar-button:active {
    color: #E5CB65 !important;
}

.navbar-button-icon {
    align-self: center;
    width: 35px;
    height: 35px;
    margin: 1%;
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
}

.cart-icon-container {
    position: relative;
    display: inline-block;
  }
  
.cart-items-count {
    position: absolute;
    top: -9px;
    right: -9px;
    background-color: #E5CB65;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: bold;
}

.navbar-button-icon:hover {
    background-color: var(--hoverButton);
    border-radius: 3px;
}

.navbar-img {
    cursor: pointer;
    padding-left: 1%;
    width: auto;
    height: 80%;
}

.user-initial-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #E5CB65;
    margin-right: 10px;
    text-decoration: none;
}

.user-initial {
    font-size: 16px;
    font-weight: bold;
    color: black;
}