.card {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    gap: 1.5rem;
    width: 550px;
    height: 180px;
    border: none;
    background-color: transparent;
}


/* Image on the card section */
.img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: 30%; */
    width: 200px;
    /* max-width: 40%; */
    height: 100%;
    position: relative;
}

.card-img {
    height: 100%;
    width: 220px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10;
}

.icon-cont {
    position: absolute;
    top: 10px;
    left: 15px;
    width: fit-content;
    height: fit-content;
}

.icon-cont>Link {
    border: none;
}

.heart-icon {
    color: var(--light-font);
    border: none;
    transform: scale(1.5);
}

.btn-item-producto-common-like {
    top: 15px;
    left: 15px;
    z-index: 1;
    position: absolute;
    background-color: transparent;
    color: white;
    border-width: inherit;
    width: 25px;
    height: 25px;
}

/* CENTER CONTAINER STYLES */
.cont-center {
    /* width: 50%; */
    /* flex: 0.5; */
    margin-left: 0.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 35%;
}

.info-cont-center {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info-cont-center>h4 {
    font-family: var(--font);
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: left;
}

.info-cont-center>span {
    font-family: var(--fontText);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
}

.info-cont-center>p {
    font-family: var(--fontText);
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

/* Estilos al contador  */


.cont-counter>p {
    font-family: var(--font);
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
}

.cont-counter>button {
    display: flex;
    justify-content: center;
    border: none;
    background-color: transparent;
    height: fit-content;
}

.cont-counter>button>* {
    transform: scale(2);
    color: #4C4E56;
    margin-left: 5px;
    margin-right: 5px;
}


/* Estilos al contenedor derecho (botón pregunta, eliminar, total) */

.cont-right {
    /* flex: 0.2; */
    display: flex;
    height: 90%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto;
}

.question-cont-right>button {
    border: none;
    margin-bottom: 1rem;
    background-color: transparent;
}

.question-cont-right>button>* {
    transform: scale(2);
    /* color: #4C4E56; */
}

.total-cont-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.total-cont-right>p {
    font-family: var(--font);
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin: 0;
    margin-bottom: .5rem;
}

.total-cont-right>button {
    border: none;
    font-family: var(--fontText);
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-decoration: underline;
    color: var(--red-font);
    margin: 0;
    padding: 0;
    background-color: transparent;
}


/* DISEÑO RESPONSIVE XS (0px < 600px) */
@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        height: auto;
    }

    .img-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 100%;
        /* width: 100%; */
        flex: 1;
    }

    .card {
        gap: 10px;
    }

    .card-img {
        /* width: 130px; */
        width: 100%;
        height: 120px;
    }

    .cont-center {
        flex: 1;
    }

    .cont-right {
        flex: 0.4;
    }

    .info-cont-center>h4 {
        font-size: 20px;
        line-height: 20px;
        text-align: left;

    }

    .info-cont-center>span {
        display: none;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: .3rem;
    }

    .info-cont-center>p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0.3rem;
        margin-top: 0;
    }

    .question-cont-right {
        margin-top: 0.5rem;
    }

    .question-cont-right>button>* {
        transform: scale(1.5);
    }

    .total-cont-right>p {
        font-size: 17px;
        line-height: 18px;
        margin-bottom: .5rem;
    }

    .total-cont-right>button {
        font-size: 13px;
        line-height: 15px;
    }
}

/* DISEÑO RESPONSIVE SM (600px < 960px) */
/* DISEÑO RESPONSIVE MD (960px < 1280px) */
/* DISEÑO RESPONSIVE LG (1280px < 1920px) */
/* DISEÑO RESPONSIVE XL (1920x < N px) */