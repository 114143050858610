.loader {
    margin: 20px;
    width: 64px;
    height: 64px;
    position: absolute;
    background-image:
        linear-gradient(#FFF 16px, transparent 0),
        linear-gradient(#E5CB65 16px, transparent 0),
        linear-gradient(#E5CB65 16px, transparent 0),
        linear-gradient(#FFF 16px, transparent 0);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: left top, left bottom, right top, right bottom;
    animation: rotate 1s linear infinite;
}

.loader-container {
    /* Estilos para el contenedor del loader */
    position: fixed;
    /* Posiciona el loader en el centro de la pantalla */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo desenfocado (blur) con transparencia */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

@keyframes rotate {
    0% {
        width: 64px;
        height: 64px;
        transform: rotate(0deg)
    }

    50% {
        width: 30px;
        height: 30px;
        transform: rotate(180deg)
    }

    100% {
        width: 64px;
        height: 64px;
        transform: rotate(360deg)
    }
}